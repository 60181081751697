<template>
    <PaymentDetailsPage />
</template>

<script>

import PaymentDetailsPage from '../components/PaymentDetailsPage.vue'

export default {
    data: () => ({
    }),
    components: {
        PaymentDetailsPage
    },
    methods: {
    },
    mounted() {
    }
}
</script>

