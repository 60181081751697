<template>
<v-container fluid fill-height>
    <v-card :class="`align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0`)" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0">
            <h1>Реквізити для оплати</h1>
        </v-card-title>
        <!-- <qrcode-vue :value="qrCode()" size="300" />
            <a :href="qrCode()">Перейти на оплату</a> -->
        <v-container fluid class="pa-0">
            <v-row no-gutters>
                <v-col col="12" sm="12" md="5" class="pt-4 pr-md-2">
                    <v-row no-gutter>
                        <v-col col="12" sm="5" class="pt-4 pr-md-2 d-flex align-stretch">
                            <v-card width="100%" elevation="6" class="d-flex flex-column mb-6">
                                <v-card-title class="subtitle-1 grey lighten-2 mb-4">
                                    QR-код для оплати
                                    <v-spacer></v-spacer>
                                    <v-icon>mdi-qrcode-scan</v-icon>
                                </v-card-title>
                                <v-card-text class="d-flex flex-column flex-grow-1 justify-center text-center">
                                    <qrcode-vue :value="qrCode()" size="200" />
                                </v-card-text>
                                <v-card-actions class="d-flex flex-column">
                                    <v-btn plain text icon @click.prevent="clickHandler(qrCode())" v-clipboard="() => qrCode()" v-clipboard:success="clipboardSuccessHandler" class="text-none text-body-2">
                                        <v-icon x-small>{{ copied[1] ? 'mdi-check' : 'mdi-content-copy' }}</v-icon> Скопіювати посилання
                                    </v-btn>
                                    <v-btn :href="qrCode()" target="_blank" class="primary mx-auto">Перейти на оплату</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col col="12" sm="7" class="pt-4 pr-md-2">
                            <v-card width="100%" elevation="6" class="mb-6">
                                <v-card-title class="subtitle-1 grey lighten-2 mb-4">
                                    Сформувати рахунок
                                    <v-spacer></v-spacer>
                                    <v-icon>mdi-file-pdf-box</v-icon>
                                </v-card-title>
                                <v-card-text>
                                    <v-select v-model="contractor_id" item-text="name" item-value="typhoon_id" :items="contractors" label="Контрагент*"></v-select>
                                    <v-radio-group row v-model="radioGroup">
                                        <template v-slot:label>
                                            <div>Платник:</div>
                                        </template>
                                        <v-radio label="Фіз. особа" value="privat"> </v-radio>
                                        <v-radio label="Юр. особа" value="company" disabled></v-radio>
                                    </v-radio-group>
                                    <v-text-field v-model="fio" label="ПІБ Платника *"></v-text-field>
                                    <v-text-field v-model="sum_vat" label="На суму *"></v-text-field>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn @click="getInvoicePdf()" :disabled="checkForm" :loading="loadingPdf" :class="loadingPdf ? `primary--text` : ``">Сформувати PDF</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                    <p class="body-2 mt-6"><b>Реквізити для оплати:</b></p>
                    <v-simple-table class="mb-6" width="100%">
                        <thead>
                            <tr>
                                <th class="grey lighten-2">Одержувач</th>
                                <th class="grey lighten-2">ЄДРПОУ</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>ТOB "СЕРВІС СУЧАСНОЇ ЕЛЕКТРОНІКИ"</td>
                                <td>41632034</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <v-simple-table class="mb-6" width="100%">
                        <thead>
                            <tr>
                                <th class="grey lighten-2">Банк</th>
                                <th class="grey lighten-2">МФО</th>
                                <th class="grey lighten-2">Р/р</th>
                                <th class="grey lighten-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Сенс-банк</td>
                                <td>300346</td>
                                <td>UA66 3003 4600 0002 6006 0244 4010 1</td>
                                <td></td>
                            </tr>
                            <!-- <tr>
                                <td>Аваль</td>
                                <td>380805</td>
                                <td>UA57 3808 0500 0000 0026 0037 9898 6</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Альфа-банк</td>
                                <td>300346</td>
                                <td>UA78 3003 4600 0002 6004 1005 7370 1</td>
                                <td></td>
                            </tr> -->
                        </tbody>
                    </v-simple-table>
                    <p class="body-2"><b>Додаткові реквізити:</b></p>
                    <v-simple-table class="mb-6" width="100%">
                        <thead>
                            <tr>
                                <th class="grey lighten-2">Контрагент</th>
                                <th class="grey lighten-2">Код контрагенту</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(c, key) in contractors" :key="key">
                                <td>
                                    {{ c.name }}
                                </td>
                                <td>
                                    {{ c.typhoon_id }}
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>

                </v-col>
                <v-col col="12" sm="12" md="7">
                    <v-card elevation="0" class="pa-0 px-md-4">
                        <v-card-title class="px-0 px-sm-4">
                            <v-icon large color="link" class="mr-4">mdi-file-document-outline</v-icon>
                            <h3>Інструкції по роботі з деякими онлайн сервісами</h3>
                        </v-card-title>

                        <v-card-text class="px-0 px-sm-4">
                            <h3>Для сплати за товар за допомогою термінала самообслуговування ПриватБанка необхідно
                                виконати дії:</h3>
                        </v-card-text>
                        <ul class="ml-sm-12 mb-4">
                            <li>Пройти ідентифікацію: натиснути кнопку на екрані «У меня нет карточки», ввести номер
                                свого мобільного
                                телефону, прийняти дзвінок від Банку, виконати отримані інструкції (зазвичай
                                натиснути 1 на клавіатурі
                                телефону);</li>
                            <li>Обрати меню «Оплата прочих услуг»;</li>
                            <li>Обрати пункт меню «Прочие»;</li>
                            <li>Обрати пункт меню «Платеж по реквизитам»:
                                <ul>
                                    <li>у діалоговій формі, що відкрилася, у графі Платник вказати ПІБ платника
                                        (особи, яка виконує
                                        сплату);</li>
                                    <li>в полях реквізити послуги ввести платіжні реквізити Платника шляхом
                                        натискання кнопки «Изменить»
                                        в кінці кожного рядка для введення реквізита
                                        <p><i><u>Получатель:</u></i><br>
                                            <i><u>Код получателя:</u></i><br>
                                            <i><u>Р/с получателя:</u></i><br>
                                            <i><u>МФО банка получателя:</u></i><br>
                                            натиснути кнопку «Продолжить»;
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            <li>У діалоговій формі, що відкрилася, для внесення Призначення платежу вписати
                                призначення платежу:<br>
                                <i><u>Призначення платежу:</u></i> сплата за товар згідно рахунку-фактурі
                                <b>ПФО#NNNNXXXXXXX</b> від
                                __.__.20ХХр. від Петренко Петра Петровича з ПДВ;<br>
                                та після перевірки на правильність внесених даных натиснути кнопку «Подтвердить»;
                            </li>
                            <li>внести купюри в купюроприймальник (зверніть увагу, що на экрані відобразиться сума
                                для внесення з
                                урахуванням комісії Банку);</li>
                            <li>роздрукуйте чек.</li>
                        </ul>

                        <v-card-text class="px-0 px-sm-4">
                            <h3>Для сплати за товар за допомогою інтернет-банкінгу у Банку, що обслуговує платника (згідно тарифів Вашого банку або провайдера послуг), 
                                необхідно виконати
                                дії:</h3>
                        </v-card-text>
                        <ul class="ml-sm-12 mb-4">
                            <li>Увійти у інтернет–банкінг, попередньо вказавши логін та пароль;</li>
                            <li>Обрать пункт меню «Платежи и переводы», або «Все услуги»;</li>
                            <li>У діалоговій формі, що відкрилася, обрати рахунок Платника, з якого буде
                                виконуватися платіж, заповнити
                                платіжні реквізити Отримувача та виконати сплату.</li>
                            <li><b>Зверніть увагу: для ідентифікації сплати необхідно обов'язково вказати номер
                                    рахунку-фактури, згідно
                                    якого виконується платіж. № Рахунку формується наступним чином: «счет
                                    ПФО#NNNNXXXXXXX від_/_/20XX»,
                                    де ПФО# - обов'язковий реквізит, NNNN – дата, XXXXXXХ – код контрагента (див.
                                    <b>Додаткові
                                        реквізити</b>). ПІБ у Призначенні платежі повинен відповідати ПІБ Платника
                                    при заповненні
                                    платіжних реквізитів у терминалі самообслуговування.</b></li>
                            <li><i>Правила формування номеру рахунку-фактури можна уточнити у Вашого менеджера.</i>
                            </li>
                        </ul>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</v-container>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex'
import QrcodeVue from 'qrcode.vue'
import {
    Base64
} from 'js-base64'

export default {
    components: {
        QrcodeVue,
    },
    directives: {},
    data: () => ({
        contractors: [],
        mime: {
            xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
            xml: "application/xml",
            html: "text/html",
            pdf: "application/pdf"
        },
        fileBlobURL: '',
        fio: '',
        sum_vat: '',
        contractor_id: null,
        radioGroup: '',
        loadingPdf: false,
        copied: {},
    }),
    props: {},
    methods: {
        ...mapActions(['touch', 'getPaymentContractors', 'getInvoice', 'setSnackbar']),
        ...mapGetters(['userInfo']),
        clickHandler(v) {
            this.$set(this.copied, v, true)
            setTimeout(() => {
                this.copied[v] = false
            }, 1000);
        },
        clipboardSuccessHandler() {
            this.setSnackbar(['success', 'Скопійовано в буфер обміну'])
        },

        qrCode() {
            //<поточна дата ДД-ММ-РРРР>
            const curDate = new Date()
            const dd = String(curDate.getDate()).padStart(2, '0');
            const mm = String(curDate.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = curDate.getFullYear();
            const currentDate = `${dd}-${mm}-${yyyy}`
            //<Код контрагента Клієнта>

            const code = `https://bank.gov.ua/qr/`
            const data =
                `BCD
002
1
UCT

ТОВ "СЕРВІС СУЧАСНОЇ ЕЛЕКТРОНІКИ"
UA663003460000026006024440101
UAH
41632034


Сплата за товар згідно рахунка-фактури ${this.contractor_id} від ${currentDate} 

`

            const coded = Base64.encode(data, true)
            return code + coded
        },
        getInvoicePdf() {
            window.URL.revokeObjectURL(this.fileBlobURL); //очистить память от ранее принятого файла
            this.loadingPdf = true
            this.touch()
                .then(() => {
                    this.getInvoice({
                            fio: this.fio,
                            sum_vat: this.sum_vat,
                            contractor_id: this.contractor_id
                        })
                        .then(data => {
                            this.saveData(data, 'invoice.pdf');
                            this.loadingPdf = false
                        })
                })
                .catch(error => console.error(error))
        },
        async saveData(data, fileName, format) {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var buf = Buffer.from(data, 'base64')
            const blob = new Blob([buf], {
                type: this.mime['pdf']
            })
            let url = window.URL.createObjectURL(blob);
            this.fileBlobURL = a.href = url;
            a.download = fileName;
            a.click();
        },

    },
    created() {
        this.getPaymentContractors()
            .then(
                d => {
                    this.contractors = d
                    this.contractor_id = d[0].typhoon_id
                }
            )
            .catch(error => console.error(error))
    },
    computed: {
        checkForm() {
            return this.sum_vat && this.fio && this.typhoon_id
        }
    }
}
</script>

<style></style>
